<template>
  <div>
    <b-card>
      <page-title v-if="titleStatus" :title="$t('locations.items')" />

      <main-table
        :fields="fields"
        :list_url="'admin/location'"
        :reloadData="reloadData"
        :params="filter"
        :createBtnLabel="$t('locations.create')"
        createRouteName="generalSettings.locationCreate"
        :excelFields="excelFields"
        excelName="locations"
        :downloadSampleStatus="true"
        downloadSampleUrl="location/excel-sample"
        :uploadFileStatus="true"
        uploadFileUrl="location/excel"
      />
    </b-card>
  </div>
</template>
<script>
import { core } from "../../../../config/pluginInit";
import LocationFilter from "../models/LocationFilter";
import searchMixin from "../../../../Mixins/searchMixin";

export default {
  mixins: [searchMixin],
  data() {
    return {
      filter: new LocationFilter(),
      fields: [
        this.$t("global.selected"),
        { key: "id", label: this.$t("table.id"), sortable: true },
        { key: "address", label: this.$t("table.address"), sortable: true },
        { key: "cityName", label: this.$t("cities.item"), sortable: true },
        {
          key: "created_at",
          label: this.$t("table.createdAt"),
          type: "dateTime",
          sortable: true,
        },
        {
          key: "actions",
          label: this.$t("table.actions"),
          type: "actions",
          actions: [
            {
              text: this.$t("global.view"),
              icon: "fas fa-eye",
              color: "info",
              ifNavigate: true,
              routeName: "generalSettings.locationView",
              routeParams: ["id"],
            },
            {
              text: this.$t("global.update"),
              icon: "fas fa-pen",
              color: "primary",
              ifNavigate: true,
              routeName: "generalSettings.locationUpdate",
              routeParams: ["id"],
            },
            {
              text: this.$t("global.delete"),
              icon: "fas fa-trash-alt",
              color: "danger",
              showAlert: true,
              actionHeader: this.$t("global.delete"),
              titleHeader: this.$t("locations.item"),
              textContent: "address",
              url: "admin/location",
              type: "delete",
            },
          ],
        },
      ],
      excelFields: {
        [this.$t("table.id")]: "id",
        [this.$t("table.address")]: "address",
        [this.$t("cities.item")]: "cityName",
        [this.$t("table.createdAt")]: "created_at",
      },
    };
  },
  props: {
    cityId: { default: "" },
  },
  mounted() {
    core.index();
  },
  created() {
    this.filter.city_id = this.cityId;
  },
};
</script>
